import io from 'socket.io-client';
import { useWebSockets } from '~/composables/useWebSockets'

export default defineNuxtPlugin(nuxtApp => {
  const ws_url = 'https://css-websocket-server-a9dlm.ondigitalocean.app'; // Replace with your server's URL
  let socket;
  const messages = useWebSockets();
  const state = {
    initiated: false,
    authenticated: false,
    connected: false,
    invitations: {}
  };

  function init(callback) {
    console.log("init///")
    socket = io(ws_url, { transports: ['websocket'] });
    // if (!state.initiated) {
      socket.on('message', (data) => {
        console.log('Message received:', data);
        messages.value.push(JSON.parse(data));
        
        // Process incoming data
      });

      socket.on('connect', () => {
        console.log('connected to socket');
      });

      socket.on('auth', (data) => {
        if (data.state === 'authenticated') {
          console.log('Authenticated successfully');
          state.authenticated = true;
          callback(null);
        } else if (data.state === 'rejected') {
          console.log('Authentication failed');
          callback(new Error('WebSocket authentication failed'));
        }
      });

      socket.on('subscription', (data) => {
        if (data.state === 'accepted') {
          console.log('Subscription accepted');
        }
      });

      socket.on('disconnect', () => {
        console.log('Socket disconnected...');
        state.connected = false;
        state.authenticated = false;
      });

      socket.on('connect', () => {
        console.log('Socket connected...');
        state.connected = true;
        subscribe(sessionStorage.getItem('token'), sessionStorage.getItem('account_id') );
      });

      // state.initiated = true;
    // }
  }

  function subscribe(token, id) {
    // if (!state.authenticated) {
    //   console.log('Not authenticated');
    //   return;
    // }
    console.log("RK::::", id)
    socket.emit('subscribe', token, id );
  }

  function auth() {
    const token = sessionStorage.getItem('token');
    if (token) {
      socket.emit('subscribe', token);
    } else {
      console.log('No token found in localStorage');
    }
  }

  function processData(data) {
    let object;
    try {
      object = JSON.parse(data);
      console.log('Processed data:', object);
      // Handle specific events here
    } catch (error) {
      console.log('Error processing data:', error);
    }
  }

  nuxtApp.provide('socket', { socket, init, subscribe, processData });
});