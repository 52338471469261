import enLocal from '../locales/en.json'


export default defineNuxtPlugin(() => ({
    
    
    provide: {

        prepareWelcomeMessage: async (companyName, callCenterName, languages, template ) => {
            const { $translate } = useNuxtApp()   
            let welcomeMessageIntro = template["welcome_message_department"].message;
            welcomeMessageIntro = welcomeMessageIntro.replace("[COMPANYNAMELABEL]", companyName);
            welcomeMessageIntro = welcomeMessageIntro.replace("[DEPARTMENT]", callCenterName);
            let result = {}
            for (let item of languages) {
                if (item.language == 'en'){
                    result[languages[0].language] = {text: welcomeMessageIntro }
                
                } else {
                
                    let translation = await $translate(welcomeMessageIntro, item.languageCode)
                    result[item.language] = {text: translation[0]}
                }
            }
            return result;
        },

        constructLanguageMenu: async (languages) => {
            const { $translate } = useNuxtApp()   
            let languageMenu = {};

            for (let [index, item] of languages.entries()){
                let text = `For `+ enLocal[`lang-${item.language}`]+ ` press ${index+1} or say `+ enLocal[`lang-${item.language}`];
                // let no_asr_text = `For `+ enLocal[`lang-${item.language}`]+ ` press ${index+1}`];
                if (item.language == "en") {
                    languageMenu[item.language] = {text,  digit: index+1, keywords: [enLocal[`lang-${item.language}`]]}
                } else {
                    let translation = await $translate(text, item.languageCode) + " "
                    let translatedLanguageName = await $translate( enLocal[`lang-${item.language}`], item.languageCode) + " "
                    languageMenu[item.language] = { text: translation + " ", digit: index+1, keywords: [translatedLanguageName] }
                } 
            }
            
            return languageMenu;
        },

        createMessage: async (languages, template, name ) => {
            const { $translate } = useNuxtApp()   
            let message = template[name].message;
            let result = {}
            for (let item of languages) {
                if (item.language == 'en'){
                    result[languages[0].language] = {text: message }
                
                } else {
                
                    let translation = await $translate(message, item.languageCode)
                    result[item.language] = {text: translation[0]}
                }
            }
            return result;
            
        },


        createIVRMenu: async (hotlines, languages ) => {
            const { $get, $translate } = useNuxtApp()  
            const response = await $get("/api/protected/templates/with-hotlines");
            const hotlineTemplate = response.data.option; //If you are calling about '[PRODUCTLABEL]', press '[DIGITLABEL]' or say '[TAGLABEL]'. 
            const languageCodes = {};
            const messages = {}

            for (let item of languages)
                languageCodes[item.language] = item.languageCode;

            const sortedData = hotlines.reduce((acc, item) => {
                if (!acc[item.language]) {
                        acc[item.language] = [];
                    }
                    acc[item.language].push(item);
                    return acc;
            }, {});
        
            for (const key in sortedData) {
                sortedData[key].sort((a, b) => a.sort_id - b.sort_id);
            }
        
            const mainObject = {};
            
            languages.forEach(item => {
                mainObject[item.language] = sortedData[item.language] || [];
            });

            for (let language in mainObject){
                messages[language] = {text: "", digits:[], keywords:[]};
               
                for (let hotline of mainObject[language]){
                    if (!("is_deleted" in hotline )){
                        let hotlineName = hotline.name;
                        let HotlineMenuText = hotlineTemplate;
                        HotlineMenuText = HotlineMenuText.replace("[PRODUCTLABEL]", hotlineName );
                        if (language != "en"){
                            let translation = await $translate(HotlineMenuText, languageCodes[language])
                            HotlineMenuText = translation[0] + " ";
                        }

                        let firstKeyword = hotline.keywords[0];
                        messages[language].keywords.push(hotline.keywords);

                        HotlineMenuText = HotlineMenuText.replace("[TAGLABEL]", firstKeyword );
                        
                        let digit = hotline.sort_id;
                        HotlineMenuText = HotlineMenuText.replace("[DIGITLABEL]", digit  );
                        messages[language].digits.push(digit);
                        messages[language].text += HotlineMenuText; 

                    }
                } 
            }
            return messages;
        }


    },
    
}));