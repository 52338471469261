// This is a specific plugin that handles multiple accounts and all headers, tokens to work with our DDB/RestAPI
export default defineNuxtPlugin(() => ({
    
    provide: {
        get: async (url) => {
            if (process.client){
                let token = sessionStorage.getItem('token');
                let headers = { 'content-type': 'application/json' };
                if (token) headers["Authorization"] = token;


                headers.account_id = sessionStorage.getItem('account_id');
                headers.callcenter_id = sessionStorage.getItem('callcenter_id');

                
                try {
                    let response = await $fetch(url, {method: 'GET', headers});     
                    return response;
                } catch(error){

                    if (error.response._data.message == 'TokenExpiredError: jwt expired'){
                        sessionStorage.removeItem('token');
                        sessionStorage.removeItem('account_id');
                        sessionStorage.removeItem('callcenter_id');
                        navigateTo('/login?event=expired');
                    } else {
                        throw error;
                    }
                }
            }
            
        },
        post: async (url, body) => {
            if (process.client){
                let token = sessionStorage.getItem('token');
                let headers = { 'content-type': 'application/json' };
                if (token) headers["Authorization"] = token;
                
                headers.account_id = sessionStorage.getItem('account_id');
                headers.callcenter_id = sessionStorage.getItem('callcenter_id');
                try {
                    let response = await $fetch(url, { method: 'POST', body, headers});
                    return response;
                } catch(error){
                    if (error.response._data.message == 'TokenExpiredError: jwt expired'){
                        sessionStorage.removeItem('token');
                        sessionStorage.removeItem('account_id');
                        sessionStorage.removeItem('callcenter_id');
                        navigateTo('/login?event=expired');
                    } else {
                        throw error;
                    }
                }
            }
        },

        patch: async (url, body) => {
            if (process.client){
                let token = sessionStorage.getItem('token');
                let headers = { 'content-type': 'application/json' };
                if (token) headers["Authorization"] = token;
                
                headers.account_id = sessionStorage.getItem('account_id');
                headers.callcenter_id = sessionStorage.getItem('callcenter_id');
                try {
                    let response = await $fetch(url, { method: 'PATCH', body, headers});
                    return response;
                } catch(error){
                    if (error.response._data.message == 'TokenExpiredError: jwt expired'){
                        sessionStorage.removeItem('token');
                        sessionStorage.removeItem('account_id');
                        sessionStorage.removeItem('callcenter_id');
                        navigateTo('/login?event=expired');
                    } else {
                        throw error;
                    }
                }
            }
        },

        delete: async (url) => {
            if (process.client){
                let token = sessionStorage.getItem('token');
                let headers = { 'content-type': 'application/json' };
                if (token) headers["Authorization"] = token;


                headers.account_id = sessionStorage.getItem('account_id');
                headers.callcenter_id = sessionStorage.getItem('callcenter_id');

                
                try {
                    let response = await $fetch(url, {method: 'DELETE', headers});     
                    return response;
                } catch(error){
                    if (error.response._data.message == 'TokenExpiredError: jwt expired'){
                        sessionStorage.removeItem('token');
                        sessionStorage.removeItem('account_id');
                        sessionStorage.removeItem('callcenter_id');
                        navigateTo('/login?event=expired');
                    } else {
                        throw error;
                    }
                }
            }
        },
    },
}));
