

// This is a specific plugin that handles multiple accounts and all headers, tokens to work with our DDB/RestAPI
export default defineNuxtPlugin(() => ({
    
    
    provide: {
        parseJwt: (token) => {
            let base64Url = token.split('.')[1];
            let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        },
        translate: async (text, language) => {
            const { $post } = useNuxtApp()    
            let translation = await $post(`/api/protected/services/translate`,
                {
                    text,
                    language
                }
            );
            return translation;
        },

        translateSchedule: async (scheduleExplaination) => {
            const { $post } = useNuxtApp()   
            let response = await $post(`/api/protected/services/schedule-translate`, { text: scheduleExplaination });
            return response;

        },

        prepareSchedule: async (schedule) => {
            function doubleDigit(d) {
                if (d > 9) {
                    return d
                } else {
                    return "0" + d;
                }
            }
        
            let text = "";
        
            for (let day in schedule) {
                if (schedule[day].active == true) {
                    text += `${day}: Open from ${doubleDigit(schedule[day].start.hours)}:${doubleDigit(schedule[day].start.minutes)} to ${doubleDigit(schedule[day].end.hours)}:${doubleDigit(schedule[day].end.minutes)} `;
                    if (schedule[day].no_lunch == true) {
                        text += " no lunch break\n ";
                    } else {
                        text += ` lunch from ${doubleDigit(schedule[day].break_start.hours)}:${doubleDigit(schedule[day].break_start.minutes)} to ${doubleDigit(schedule[day].break_end.hours)}:${doubleDigit(schedule[day].break_end.minutes)}  \n`;
                    }
                } else {
                    text += `${day}: closed\n`;
                }
            }
            return text;
        },
        prepareScheduleArray: async (schedule) => {
            function doubleDigit(d) {
                if (d > 9) {
                    return d
                } else {
                    return "0" + d;
                }
            }
        
            let result = [];
            
            for (let day in schedule) {
                if (schedule[day].active == true) {
                    let text = `Open from ${doubleDigit(schedule[day].start.hours)}:${doubleDigit(schedule[day].start.minutes)} to ${doubleDigit(schedule[day].end.hours)}:${doubleDigit(schedule[day].end.minutes)} `;
                    if (schedule[day].no_lunch == true) {
                        text += " no lunch break\n ";
                    } else {
                        text += ` lunch from ${doubleDigit(schedule[day].break_start.hours)}:${doubleDigit(schedule[day].break_start.minutes)} to ${doubleDigit(schedule[day].break_end.hours)}:${doubleDigit(schedule[day].break_end.minutes)}  \n`;
                    }
                    result.push({day, text});
                } else {
                    let text = `${day}: closed\n`;
                    result.push({day, text:'closed'});
                }
            }
            return result;
        },
        tts: async( text, languageCode, voice, filename="") => {
            const { $post } = useNuxtApp()    
            let body = {   
                text, 
                languageCode, 
                voice 
            }
            if (filename != "") body.filename = filename;
            let tts_response = await $post(`/api/protected/services/tts`, body);
            return tts_response.data;
        }
    },
    
}));