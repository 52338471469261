import { default as _91id_93yIPxsuTdmsMeta } from "/app/pages/callcenter/[id].vue?macro=true";
import { default as createfRg0NMAeMKMeta } from "/app/pages/callcenter/create.vue?macro=true";
import { default as indexB2gpQSeNjJMeta } from "/app/pages/company/[id]/callcenters/[callcenter_id]/index.vue?macro=true";
import { default as index_45old4bxe1vpEDRMeta } from "/app/pages/company/[id]/callcenters/[callcenter_id]/settings/index-old.vue?macro=true";
import { default as indexgQyd43YfC0Meta } from "/app/pages/company/[id]/callcenters/index.vue?macro=true";
import { default as index_45oldKbvBHJ1qxYMeta } from "/app/pages/company/[id]/index-old.vue?macro=true";
import { default as indexoEJhnmdqRrMeta } from "/app/pages/company/[id]/index.vue?macro=true";
import { default as indexgmVYTOuvm9Meta } from "/app/pages/company/[id]/phone-numbers/index.vue?macro=true";
import { default as purchase_45did_45successIIwCB4ZenPMeta } from "/app/pages/company/[id]/purchase-did-success.vue?macro=true";
import { default as upgrade_45successV4fFygdfyzMeta } from "/app/pages/company/[id]/upgrade-success.vue?macro=true";
import { default as create49c2VEwNwnMeta } from "/app/pages/company/create.vue?macro=true";
import { default as indexadJieniFk0Meta } from "/app/pages/company/index.vue?macro=true";
import { default as index3J5r3Sf7qAMeta } from "/app/pages/index/index.vue?macro=true";
import { default as index7ESp5cHmPlMeta } from "/app/pages/invitations/index.vue?macro=true";
import { default as autoacceptsCYflXRBheMeta } from "/app/pages/login/autoaccept.vue?macro=true";
import { default as indexgK5VicK7y2Meta } from "/app/pages/login/index.vue?macro=true";
import { default as googlefwpS5czamDMeta } from "/app/pages/login/validate/google.vue?macro=true";
import { default as regularh82EC7cPoIMeta } from "/app/pages/login/validate/regular.vue?macro=true";
import { default as testL3AVDvhyXeMeta } from "/app/pages/test.vue?macro=true";
import { default as _1exY307y3TSMeta } from "/app/pages/tests/1.vue?macro=true";
import { default as _2COMUC9wIAMMeta } from "/app/pages/tests/2.vue?macro=true";
import { default as _3GNGTGOzkwOMeta } from "/app/pages/tests/3.vue?macro=true";
import { default as _4C3ulGqw9DlMeta } from "/app/pages/tests/4.vue?macro=true";
import { default as agent_45hotlinesXhUbb2CeNGMeta } from "/app/pages/tests/agent-hotlines.vue?macro=true";
import { default as approvalsf7y0ICo6hNMeta } from "/app/pages/tests/approvals.vue?macro=true";
import { default as ddWD2QdFXylhMeta } from "/app/pages/tests/dd.vue?macro=true";
import { default as hotlinesRcx83kM9pqMeta } from "/app/pages/tests/hotlines.vue?macro=true";
import { default as presensebNqgvdNd98Meta } from "/app/pages/tests/presense.vue?macro=true";
import { default as reactivegWQj2acnIfMeta } from "/app/pages/tests/reactive.vue?macro=true";
import { default as select1LTCVVI1zvMeta } from "/app/pages/tests/select.vue?macro=true";
import { default as wskUGm4LV8aIMeta } from "/app/pages/tests/ws.vue?macro=true";
import { default as index2HgavUPmh3Meta } from "/app/pages/welcome/index.vue?macro=true";
export default [
  {
    name: _91id_93yIPxsuTdmsMeta?.name ?? "callcenter-id",
    path: _91id_93yIPxsuTdmsMeta?.path ?? "/callcenter/:id()",
    meta: _91id_93yIPxsuTdmsMeta || {},
    alias: _91id_93yIPxsuTdmsMeta?.alias || [],
    redirect: _91id_93yIPxsuTdmsMeta?.redirect,
    component: () => import("/app/pages/callcenter/[id].vue").then(m => m.default || m)
  },
  {
    name: createfRg0NMAeMKMeta?.name ?? "callcenter-create",
    path: createfRg0NMAeMKMeta?.path ?? "/callcenter/create",
    meta: createfRg0NMAeMKMeta || {},
    alias: createfRg0NMAeMKMeta?.alias || [],
    redirect: createfRg0NMAeMKMeta?.redirect,
    component: () => import("/app/pages/callcenter/create.vue").then(m => m.default || m)
  },
  {
    name: indexB2gpQSeNjJMeta?.name ?? "company-id-callcenters-callcenter_id",
    path: indexB2gpQSeNjJMeta?.path ?? "/company/:id()/callcenters/:callcenter_id()",
    meta: indexB2gpQSeNjJMeta || {},
    alias: indexB2gpQSeNjJMeta?.alias || [],
    redirect: indexB2gpQSeNjJMeta?.redirect,
    component: () => import("/app/pages/company/[id]/callcenters/[callcenter_id]/index.vue").then(m => m.default || m)
  },
  {
    name: index_45old4bxe1vpEDRMeta?.name ?? "company-id-callcenters-callcenter_id-settings-index-old",
    path: index_45old4bxe1vpEDRMeta?.path ?? "/company/:id()/callcenters/:callcenter_id()/settings/index-old",
    meta: index_45old4bxe1vpEDRMeta || {},
    alias: index_45old4bxe1vpEDRMeta?.alias || [],
    redirect: index_45old4bxe1vpEDRMeta?.redirect,
    component: () => import("/app/pages/company/[id]/callcenters/[callcenter_id]/settings/index-old.vue").then(m => m.default || m)
  },
  {
    name: indexgQyd43YfC0Meta?.name ?? "company-id-callcenters",
    path: indexgQyd43YfC0Meta?.path ?? "/company/:id()/callcenters",
    meta: indexgQyd43YfC0Meta || {},
    alias: indexgQyd43YfC0Meta?.alias || [],
    redirect: indexgQyd43YfC0Meta?.redirect,
    component: () => import("/app/pages/company/[id]/callcenters/index.vue").then(m => m.default || m)
  },
  {
    name: index_45oldKbvBHJ1qxYMeta?.name ?? "company-id-index-old",
    path: index_45oldKbvBHJ1qxYMeta?.path ?? "/company/:id()/index-old",
    meta: index_45oldKbvBHJ1qxYMeta || {},
    alias: index_45oldKbvBHJ1qxYMeta?.alias || [],
    redirect: index_45oldKbvBHJ1qxYMeta?.redirect,
    component: () => import("/app/pages/company/[id]/index-old.vue").then(m => m.default || m)
  },
  {
    name: indexoEJhnmdqRrMeta?.name ?? "company-id",
    path: indexoEJhnmdqRrMeta?.path ?? "/company/:id()",
    meta: indexoEJhnmdqRrMeta || {},
    alias: indexoEJhnmdqRrMeta?.alias || [],
    redirect: indexoEJhnmdqRrMeta?.redirect,
    component: () => import("/app/pages/company/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexgmVYTOuvm9Meta?.name ?? "company-id-phone-numbers",
    path: indexgmVYTOuvm9Meta?.path ?? "/company/:id()/phone-numbers",
    meta: indexgmVYTOuvm9Meta || {},
    alias: indexgmVYTOuvm9Meta?.alias || [],
    redirect: indexgmVYTOuvm9Meta?.redirect,
    component: () => import("/app/pages/company/[id]/phone-numbers/index.vue").then(m => m.default || m)
  },
  {
    name: purchase_45did_45successIIwCB4ZenPMeta?.name ?? "company-id-purchase-did-success",
    path: purchase_45did_45successIIwCB4ZenPMeta?.path ?? "/company/:id()/purchase-did-success",
    meta: purchase_45did_45successIIwCB4ZenPMeta || {},
    alias: purchase_45did_45successIIwCB4ZenPMeta?.alias || [],
    redirect: purchase_45did_45successIIwCB4ZenPMeta?.redirect,
    component: () => import("/app/pages/company/[id]/purchase-did-success.vue").then(m => m.default || m)
  },
  {
    name: upgrade_45successV4fFygdfyzMeta?.name ?? "company-id-upgrade-success",
    path: upgrade_45successV4fFygdfyzMeta?.path ?? "/company/:id()/upgrade-success",
    meta: upgrade_45successV4fFygdfyzMeta || {},
    alias: upgrade_45successV4fFygdfyzMeta?.alias || [],
    redirect: upgrade_45successV4fFygdfyzMeta?.redirect,
    component: () => import("/app/pages/company/[id]/upgrade-success.vue").then(m => m.default || m)
  },
  {
    name: create49c2VEwNwnMeta?.name ?? "company-create",
    path: create49c2VEwNwnMeta?.path ?? "/company/create",
    meta: create49c2VEwNwnMeta || {},
    alias: create49c2VEwNwnMeta?.alias || [],
    redirect: create49c2VEwNwnMeta?.redirect,
    component: () => import("/app/pages/company/create.vue").then(m => m.default || m)
  },
  {
    name: indexadJieniFk0Meta?.name ?? "company",
    path: indexadJieniFk0Meta?.path ?? "/company",
    meta: indexadJieniFk0Meta || {},
    alias: indexadJieniFk0Meta?.alias || [],
    redirect: indexadJieniFk0Meta?.redirect,
    component: () => import("/app/pages/company/index.vue").then(m => m.default || m)
  },
  {
    name: index3J5r3Sf7qAMeta?.name ?? "index",
    path: index3J5r3Sf7qAMeta?.path ?? "/",
    meta: index3J5r3Sf7qAMeta || {},
    alias: index3J5r3Sf7qAMeta?.alias || [],
    redirect: index3J5r3Sf7qAMeta?.redirect,
    component: () => import("/app/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: index7ESp5cHmPlMeta?.name ?? "invitations",
    path: index7ESp5cHmPlMeta?.path ?? "/invitations",
    meta: index7ESp5cHmPlMeta || {},
    alias: index7ESp5cHmPlMeta?.alias || [],
    redirect: index7ESp5cHmPlMeta?.redirect,
    component: () => import("/app/pages/invitations/index.vue").then(m => m.default || m)
  },
  {
    name: autoacceptsCYflXRBheMeta?.name ?? "login-autoaccept",
    path: autoacceptsCYflXRBheMeta?.path ?? "/login/autoaccept",
    meta: autoacceptsCYflXRBheMeta || {},
    alias: autoacceptsCYflXRBheMeta?.alias || [],
    redirect: autoacceptsCYflXRBheMeta?.redirect,
    component: () => import("/app/pages/login/autoaccept.vue").then(m => m.default || m)
  },
  {
    name: indexgK5VicK7y2Meta?.name ?? "login",
    path: indexgK5VicK7y2Meta?.path ?? "/login",
    meta: indexgK5VicK7y2Meta || {},
    alias: indexgK5VicK7y2Meta?.alias || [],
    redirect: indexgK5VicK7y2Meta?.redirect,
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: googlefwpS5czamDMeta?.name ?? "login-validate-google",
    path: googlefwpS5czamDMeta?.path ?? "/login/validate/google",
    meta: googlefwpS5czamDMeta || {},
    alias: googlefwpS5czamDMeta?.alias || [],
    redirect: googlefwpS5czamDMeta?.redirect,
    component: () => import("/app/pages/login/validate/google.vue").then(m => m.default || m)
  },
  {
    name: regularh82EC7cPoIMeta?.name ?? "login-validate-regular",
    path: regularh82EC7cPoIMeta?.path ?? "/login/validate/regular",
    meta: regularh82EC7cPoIMeta || {},
    alias: regularh82EC7cPoIMeta?.alias || [],
    redirect: regularh82EC7cPoIMeta?.redirect,
    component: () => import("/app/pages/login/validate/regular.vue").then(m => m.default || m)
  },
  {
    name: testL3AVDvhyXeMeta?.name ?? "test",
    path: testL3AVDvhyXeMeta?.path ?? "/test",
    meta: testL3AVDvhyXeMeta || {},
    alias: testL3AVDvhyXeMeta?.alias || [],
    redirect: testL3AVDvhyXeMeta?.redirect,
    component: () => import("/app/pages/test.vue").then(m => m.default || m)
  },
  {
    name: _1exY307y3TSMeta?.name ?? "tests-1",
    path: _1exY307y3TSMeta?.path ?? "/tests/1",
    meta: _1exY307y3TSMeta || {},
    alias: _1exY307y3TSMeta?.alias || [],
    redirect: _1exY307y3TSMeta?.redirect,
    component: () => import("/app/pages/tests/1.vue").then(m => m.default || m)
  },
  {
    name: _2COMUC9wIAMMeta?.name ?? "tests-2",
    path: _2COMUC9wIAMMeta?.path ?? "/tests/2",
    meta: _2COMUC9wIAMMeta || {},
    alias: _2COMUC9wIAMMeta?.alias || [],
    redirect: _2COMUC9wIAMMeta?.redirect,
    component: () => import("/app/pages/tests/2.vue").then(m => m.default || m)
  },
  {
    name: _3GNGTGOzkwOMeta?.name ?? "tests-3",
    path: _3GNGTGOzkwOMeta?.path ?? "/tests/3",
    meta: _3GNGTGOzkwOMeta || {},
    alias: _3GNGTGOzkwOMeta?.alias || [],
    redirect: _3GNGTGOzkwOMeta?.redirect,
    component: () => import("/app/pages/tests/3.vue").then(m => m.default || m)
  },
  {
    name: _4C3ulGqw9DlMeta?.name ?? "tests-4",
    path: _4C3ulGqw9DlMeta?.path ?? "/tests/4",
    meta: _4C3ulGqw9DlMeta || {},
    alias: _4C3ulGqw9DlMeta?.alias || [],
    redirect: _4C3ulGqw9DlMeta?.redirect,
    component: () => import("/app/pages/tests/4.vue").then(m => m.default || m)
  },
  {
    name: agent_45hotlinesXhUbb2CeNGMeta?.name ?? "tests-agent-hotlines",
    path: agent_45hotlinesXhUbb2CeNGMeta?.path ?? "/tests/agent-hotlines",
    meta: agent_45hotlinesXhUbb2CeNGMeta || {},
    alias: agent_45hotlinesXhUbb2CeNGMeta?.alias || [],
    redirect: agent_45hotlinesXhUbb2CeNGMeta?.redirect,
    component: () => import("/app/pages/tests/agent-hotlines.vue").then(m => m.default || m)
  },
  {
    name: approvalsf7y0ICo6hNMeta?.name ?? "tests-approvals",
    path: approvalsf7y0ICo6hNMeta?.path ?? "/tests/approvals",
    meta: approvalsf7y0ICo6hNMeta || {},
    alias: approvalsf7y0ICo6hNMeta?.alias || [],
    redirect: approvalsf7y0ICo6hNMeta?.redirect,
    component: () => import("/app/pages/tests/approvals.vue").then(m => m.default || m)
  },
  {
    name: ddWD2QdFXylhMeta?.name ?? "tests-dd",
    path: ddWD2QdFXylhMeta?.path ?? "/tests/dd",
    meta: ddWD2QdFXylhMeta || {},
    alias: ddWD2QdFXylhMeta?.alias || [],
    redirect: ddWD2QdFXylhMeta?.redirect,
    component: () => import("/app/pages/tests/dd.vue").then(m => m.default || m)
  },
  {
    name: hotlinesRcx83kM9pqMeta?.name ?? "tests-hotlines",
    path: hotlinesRcx83kM9pqMeta?.path ?? "/tests/hotlines",
    meta: hotlinesRcx83kM9pqMeta || {},
    alias: hotlinesRcx83kM9pqMeta?.alias || [],
    redirect: hotlinesRcx83kM9pqMeta?.redirect,
    component: () => import("/app/pages/tests/hotlines.vue").then(m => m.default || m)
  },
  {
    name: presensebNqgvdNd98Meta?.name ?? "tests-presense",
    path: presensebNqgvdNd98Meta?.path ?? "/tests/presense",
    meta: presensebNqgvdNd98Meta || {},
    alias: presensebNqgvdNd98Meta?.alias || [],
    redirect: presensebNqgvdNd98Meta?.redirect,
    component: () => import("/app/pages/tests/presense.vue").then(m => m.default || m)
  },
  {
    name: reactivegWQj2acnIfMeta?.name ?? "tests-reactive",
    path: reactivegWQj2acnIfMeta?.path ?? "/tests/reactive",
    meta: reactivegWQj2acnIfMeta || {},
    alias: reactivegWQj2acnIfMeta?.alias || [],
    redirect: reactivegWQj2acnIfMeta?.redirect,
    component: () => import("/app/pages/tests/reactive.vue").then(m => m.default || m)
  },
  {
    name: select1LTCVVI1zvMeta?.name ?? "tests-select",
    path: select1LTCVVI1zvMeta?.path ?? "/tests/select",
    meta: select1LTCVVI1zvMeta || {},
    alias: select1LTCVVI1zvMeta?.alias || [],
    redirect: select1LTCVVI1zvMeta?.redirect,
    component: () => import("/app/pages/tests/select.vue").then(m => m.default || m)
  },
  {
    name: wskUGm4LV8aIMeta?.name ?? "tests-ws",
    path: wskUGm4LV8aIMeta?.path ?? "/tests/ws",
    meta: wskUGm4LV8aIMeta || {},
    alias: wskUGm4LV8aIMeta?.alias || [],
    redirect: wskUGm4LV8aIMeta?.redirect,
    component: () => import("/app/pages/tests/ws.vue").then(m => m.default || m)
  },
  {
    name: index2HgavUPmh3Meta?.name ?? "welcome",
    path: index2HgavUPmh3Meta?.path ?? "/welcome",
    meta: index2HgavUPmh3Meta || {},
    alias: index2HgavUPmh3Meta?.alias || [],
    redirect: index2HgavUPmh3Meta?.redirect,
    component: () => import("/app/pages/welcome/index.vue").then(m => m.default || m)
  }
]