import { createI18n } from 'vue-i18n'
import en from '../locales/en.json'
import ru from '../locales/ru.json'


export default defineNuxtPlugin((nuxtApp) => {
    // const userLang = navigator.language || navigator.userLanguage;
    let browserLanguage = 'en';
    if (process.client )
            browserLanguage = navigator.language.split("-")[0];     

    const i18n = createI18n({
        legacy: false,
        globalInjection: true,
        fallbackLocale: 'en',
        locale: browserLanguage,
        messages: {
            en, 
            ru 
        }
    })
    nuxtApp.vueApp.use(i18n)
    // nuxtApp.vueApp.provide('i18n', i18n.t);
});

