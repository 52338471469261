import {defineNuxtPlugin, useRequestHeaders} from '#app';
import MobileDetect from 'mobile-detect';

export default defineNuxtPlugin(() => {
    let headers = useRequestHeaders()

    const md = process.server ? new MobileDetect(headers['user-agent']) : new MobileDetect(navigator.userAgent)
    let isMobile = md.phone() !== null || md.mobile() === 'UnknownMobile'
    // console.log(md.ua)
    // if (/Mobi|Android/i.test(md.ua)) {
    //     isMobile = true
    //     console.log(isMobile)
    // }

    const isTablet = md.tablet() !== null || md.mobile() === 'UnknownTablet'
    
    const isDesktop = !isMobile && !isTablet

    return {
        provide: {
            isMobile: () => isMobile,
            isTablet: () => isTablet,
            isDesktop: () => isDesktop
        },
    };
})
